
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useRouter } from "vue-router";
import emailingApi from "@/core/services/EmailingApi";
import { ElMessage, ElMessageBox } from "element-plus";

export default defineComponent({
    name: "builder",
    components: {},
    data() {
        return {
            segmentCount: "",
            loading: true,
            segmentSearch: "",
            segments: [] as any,
            currentPage: 1,
            totalPages: 0,
            selectedSegments: [] as any,
            actualFilter: "",
            actualCol: "",
            segmentsTh: [
                { label: "Title", class: "min-w-60px", colName: "title" },
                { label: "Quantity", class: "min-w-60px", colName: "quantity" },
                { label: "Last used", class: "min-w-60px", colName: "last_used" },
                { label: "Number of uses", class: "min-w-60px", colName: "uses_number" },
                { label: "Creation date", class: "min-w-60px", colName: "creation_date" },
            ],
            perm_pos: "",
        };
    },
    setup() {
        const router = useRouter();
        const resultsPerPage = 10;

        onMounted(() => {
            setCurrentPageBreadcrumbs("Segments list", []);
        });

        function createDebounce() {
            let timeout: any;
            return function (fnc, delayMs) {
                clearTimeout(timeout);
                timeout = setTimeout(() => {
                    fnc();
                }, delayMs || 500);
            };
        }

        return { router, resultsPerPage, debounce: createDebounce() };
    },
    mounted() {
        this.getSegments();
        this.perm_pos = localStorage.getItem("perm_pos") as any;
    },
    methods: {
        handleSizeChange() {
            this.getSegments();
        },
        getSegments() {
            this.loading = true;

            var payload = {};

            if (this.actualFilter && this.actualCol) {
                // Si tri et pas de filtres
                payload = {
                    p: this.currentPage,
                    presult: this.resultsPerPage,
                    column: this.actualCol,
                    order: this.actualFilter,
                };
            } else {
                // Sinon on affiche tout
                payload = {
                    p: this.currentPage,
                    presult: this.resultsPerPage,
                };
            }

            if (this.segmentSearch) {
                payload = { ...payload, query: this.segmentSearch };
            }

            emailingApi
                .listSegments(payload)
                .then((res) => {
                    this.segments = res.data.segment_list;
                    this.segmentCount = res.data.segment_count;
                    this.totalPages = res.data.page_count;
                    this.segments.map((segment: any) => {
                        segment.date_updated = new Date(segment.date_updated).toLocaleDateString();
                        segment.date_created = new Date(segment.date_created).toLocaleDateString();
                    });
                    this.loading = false;
                })
                .catch((err) => {
                    console.log(err);
                    this.loading = false;
                });
        },
        editSegment(id?: number) {
            if (id) this.router.push({ name: "segment-details", params: { segment_id: id } });
            else this.router.push({ name: "segment-add" });
        },
        deleteSegments(id?: number) {
            ElMessageBox.confirm("Are you sure to delete this segment?", "Delete segment", {
                confirmButtonText: "Yes",
                cancelButtonText: "Cancel",
                customClass: "custom-modal",
                cancelButtonClass: "cancel-modal",
            })
                .then(() => {
                    emailingApi
                        .deleteSegment(id)
                        .then(() => {
                            this.getSegments();
                            ElMessage({
                                type: "success",
                                message: "Segment deleted",
                            });
                        })
                        .catch((err) => {
                            console.log(err);
                            ElMessage({
                                type: "error",
                                message: "An error occurred",
                            });
                        });
                })
                .catch(() => {
                    console.log("Canceled");
                });
        },
        sortColumn(column: string, id: number) {
            var arrows = document.getElementById("chevrons" + id);

            // Hide all arrows
            Array.from(document.getElementsByClassName("chevrons-container") as any).map((filter: any) => {
                filter.style.display = "none";
            });

            // Display the right arrows
            if (arrows) arrows.style.display = "flex";

            // If we click for the first time on a column OR if we change the column
            if (this.actualCol === "" || this.actualCol !== column) {
                this.actualCol = column;
                this.actualFilter = "asc";
                this.handleChevron("show", "up");
                this.handleChevron("hide", "down");

                // Else if we click on the same column
            } else if (this.actualCol === column) {
                if (this.actualFilter === "asc") {
                    this.handleChevron("hide", "up");
                    this.handleChevron("show", "down");
                    this.actualFilter = "desc";
                } else if (this.actualFilter === "desc") {
                    this.handleChevron("show", "up");
                    this.handleChevron("hide", "down");
                    this.actualFilter = "asc";
                }
            }
            this.getSegments();
        },
        handleChevron(action: string, direction: string) {
            var chevrons: any;

            if (direction === "up") chevrons = Array.from(document.getElementsByClassName("fa-chevron-up") as any);
            else if (direction === "down") chevrons = Array.from(document.getElementsByClassName("fa-chevron-down") as any);

            if (action === "show")
                chevrons.map((chevron: any) => {
                    chevron.classList.add("active-chevron");
                });
            else if (action === "hide")
                chevrons.map((chevron: any) => {
                    chevron.classList.remove("active-chevron");
                });
        },
        handlePagination(pageNumber: any) {
            this.selectedSegments = [];
            this.currentPage = pageNumber;
            this.getSegments();
        },
        formatNumber(num) {
            return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
        },
    },
});
